* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  color: white;
}

.app {
  background: url("../assets/background.png"),
    linear-gradient(
      180deg,
      rgba(78, 49, 188, 0.15) 0%,
      rgba(162, 35, 241, 0.15) 100%
    ),
    black;
  background-size: 100%;
  height: 100vh;
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
}

.yellow-text {
  color: yellow;
}

.inline-flex {
  display: flex;
  align-items: center;
}
.center {
  text-align: center;
}
